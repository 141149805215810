<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Foydalanuvchi qo'shish</h5>
                </div>
                <div class="card-body tab2-card">
                    <div class="red" *ngIf="tmp.error">{{tmp.error}}</div>
                    <form [formGroup]="accountForm" (submit)="onAdd($event)" class="needs-validation user-add" novalida>
                        <div class="form-group row">
                            <label for="validationCustom1" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Ismi
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="fname" 
                                    placeholder="Ismi" 
                                    type="text"
                                    id="validationCustom1"
                                    [ngClass]="{'is-invalid': f.fname.touched && f.fname.errors}"
                                >
                                <div class="red" *ngIf="f.fname.touched && f.fname.errors && f.fname.errors.required">Qator to'ldirilishi shart!</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom2" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Familiyasi
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="lname" 
                                    placeholder="Familiyasi" 
                                    type="text"
                                    id="validationCustom2"
                                    [ngClass]="{'is-invalid': f.lname.touched && f.lname.errors}"
                                >
                                <div class="red" *ngIf="f.lname.touched && f.lname.errors && f.lname.errors.required">Qator to'ldirilishi shart!</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom3" class="col-xl-3 col-md-4">
                                Sharifi
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="patronymic" 
                                    placeholder="Sharifi" 
                                    type="text"
                                    id="validationCustom3"
                                    [ngClass]="{'is-invalid': f.patronymic.touched && f.patronymic.errors}"
                                >
                                <div class="red" *ngIf="f.patronymic.touched && f.patronymic.errors && f.patronymic.errors.required">Qator to'ldirilishi shart!</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom31" class="col-xl-3 col-md-4">
                                Tug‘ilgan yili 
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="born" 
                                    placeholder="Tug‘ilgan yili" 
                                    type="number"
                                    id="validationCustom31"
                                    [ngClass]="{'is-invalid': f.born.touched && f.born.errors}"
                                >
                                <div class="red" *ngIf="f.born.touched && f.born.errors && f.born.errors.required">Qator to'ldirilishi shart!</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom32" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Lavozimi
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="rank">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option>Professor</option>
                                    <option>Dotsent</option>
                                    <option>katta o‘qituvchi</option>
                                    <option>Assistent</option>
                                    <option>Kafedra mudiri</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom33" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Stavkasi
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="wagerate">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option>1</option>
                                    <option>1,5</option>
                                    <option>0,5</option>
                                    <option>0,25</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom34" class="col-xl-3 col-md-4">
                                Ilmiy darajasi
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="academic_degree">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option>Fan doktori</option>
                                    <option>Fan nomzodi</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom35" class="col-xl-3 col-md-4">
                                Ilmiy unvoni
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="academic_title">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option>Dotsent</option>
                                    <option>Professor</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom36" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Shtat turi
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="state_type">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option>Asosiy</option> 
                                    <option>Ichki o'rindosh</option>
                                    <option>Tashqi o'rindosh</option>
                                    <option>Soatbay</option>

                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom4" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Fakultet
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="fakul">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option *ngFor="let item of tizim">{{item.fakultet}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="accountForm.value.fakul">
                            <label for="validationCustom5" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Kafedra
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="kafed">
                                    <option disabled selected value> -- tanlash -- </option>
                                    <option *ngFor="let item of tanKafedra().kafedralar">{{item}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="pull-right">
                            <button type="submit" class="btn btn-primary" [disabled]="accountForm.invalid">Qo'shish</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>