<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="!tmp.loading">
    <div class="card">
        <div class="card-header">
            <h5>Foydalanuvchilar ma'lumotlari</h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Foydalanuvchi yaratish</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <ng2-smart-table 
                        [settings]="settings" 
                        [source]="user_list"
                        (deleteConfirm)="onDeleteConfirm($event)"
                        (editConfirm)="onEditConfirm($event)"
                    ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>
<!-- Container-fluid Ends-->